import React, { useState } from "react";
import "./index.css";
import logo from "./logo.png"; // Make sure to import the logo image
import userImage from "./user.jpg"; // Make sure to import the user image

import {
  Box,
  Drawer,
  Grid,
  hexToRgb,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import comingSoon from "../../assets/images/coming-soon.jpg";
import footer from "../../assets/images/FrankIcons/download-mobile-app-banner@4x.png";
import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import googlePlayBtn from "../../assets/images/FrankIcons/french-google-play.png";

import { defaultLabelColors, spacing } from "utils/constants";
import iconOne from "../../assets/images/FrankIcons/livraison.png";
import iconTwo from "../../assets/images/FrankIcons/economique.png";
import iconThree from "../../assets/images/FrankIcons/assuree.png";
import CustomButton from "ui-components/CustomButton";
import imageParticular from "../../assets/images/FrankIcons/particlular.jpg";
import imageProfessional from "../../assets/images/FrankIcons/professional.jpg";
import imageBannerTwo from "../../assets/images/FrankIcons/mobile-screens-steps.png";
import trainImage from "../../assets/images/FrankIcons/trainImage.jpg";
import boxImage from "../../assets/images/FrankIcons/boxImage.jpg";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { AppStoreFR, FrankIcon } from "assets/images/FrankIcons";
import frankWhiteLogo from "../../assets/images/white-logo-frank.png";
import MenuIcon from "@mui/icons-material/Menu";
import bottomAction from "../../assets/images/FrankIcons/bottom-action.png";
import { useNavigate } from "react-router-dom";
import googlePlayFrench from "../../assets/images/google-play-store-french.png";
import { ArrowCircleRight, ArrowForward } from "@mui/icons-material";
import homeBanner from "../../assets/images/FrankIcons/home-banner.png";
import homeBannerMobile from "../../assets/images/FrankIcons/home-banner-mobile.png";
import arrow from "../../assets/images/FrankIcons/arrow-dots.png";
import MobileView from "./MobileView";
import appStoreFrench from "../../assets/images/FrankIcons/app-store.png";
import useStyles from "routes/style-jss";
import zIndex from "@mui/material/styles/zIndex";
import NewHeader from "ui-components/NewHeader";
import { Helmet } from "react-helmet";

function LandingPage(props: any) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [dropdown1Open, setDropdown1Open] = useState(false);
  const [dropdown2Open, setDropdown2Open] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDropdown1 = () => {
    setDropdown1Open(!dropdown1Open);
    if (dropdown2Open) setDropdown2Open(false);
  };

  const toggleDropdown2 = () => {
    setDropdown2Open(!dropdown2Open);
    if (dropdown1Open) setDropdown1Open(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeDropdowns = () => {
    setDropdown1Open(false);
    setDropdown2Open(false);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    closeDropdowns();
  };

  const menuItems = [
    { text: "Accueil", link: "/" },
    { text: "Particulier", link: "/" },
    { text: "Transporteur", link: "/" },
    { text: "Déménagement", link: "/" },
    { text: "Market place", link: "/" },
    { text: "Stockage colis", link: "/" },
    { text: "Blog", link: "/" },
  ];

  return (
    <>
      <Helmet>
        <title>Frank : Service de livraison collaborative </title>
        <link rel="canonical" href="https://frankme.com/" />
        <meta
          name="description"
          content="Envoyez vos colis à peu de frais ou gagnez de l’argent en transportant ceux des particuliers. Découvrez  notre plateforme de livraison collaborative. Envoyez vos colis grâce à vos voisins ! 
"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Frank",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+33-1-2345-6789",
              contactType: "Customer Service",
              areaServed: "FR",
              availableLanguage: "French",
            },
          })}
        </script>
      </Helmet>
      <div className="web-screen">
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{
            background: "#ee6931",
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "30px",
          }}
        >
          <Grid xs={12} item style={{ zIndex: 99 }}>
            <NewHeader />
          </Grid>
        </Grid>

        <Grid container spacing={spacing?.spacing2} style={{ height: "100%" }}>
          <Grid xs={12} item style={{ paddingTop: 100 }}>
            <div className="main-div">
              <div className="home-banner-inner-div">
                <p className="home-banner-inner-text">
                  LE CHOIX
                  <br />
                  D'UNE LIVRAISON
                  <br />
                  <span
                    style={{ color: "#f9d9b6", fontFamily: "Raleway-Italic" }}
                  >
                    QUI VOUS RESSEMBLE!
                  </span>
                </p>
                <p
                  style={{
                    fontFamily: "Raleway-Italic",
                    fontSize: "22px",
                    color: "#fff",
                  }}
                >
                  Envoyez des colis à prix réduits, de maniére
                  <br />
                  éco-responsble et découvrez toute une
                  <br />
                  communauté.
                </p>
                <div
                  style={{ marginTop: "20px" }}
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  <CustomButton
                    value={"DÉCOUVREZ QUI NOUS SOMMES"}
                    endIcon={
                      <ArrowCircleRight
                        style={{ color: "#f9d9b6", fontSize: "32px" }}
                        onClick={() => {
                          navigate("/about-us");
                        }}
                      />
                    }
                    style={{
                      background: "#3026f1",
                      color: "#fff",
                      fontFamily: "Raleway-Variable",
                      fontWeight: "700px",
                      borderRadius: "8px",
                      fontSize: "16px",
                      height: "55px",
                      zIndex: "111111",
                      cursor: "pointer",
                    }}
                    classesNames={""}
                    onClick={() => {
                      navigate("/about-us");
                    }}
                  />
                </div>
              </div>
              <img
                src={homeBanner}
                alt="Web Banner"
                style={{
                  width: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 60,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: -1,
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "170px",
          }}
        >
          <Grid xs={12} sm={6} md={3} item style={{ maxWidth: "300px" }}>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontWeight: "900",
                fontSize: "45px",
                color: "#3026f1",
              }}
            >
              POURQUOI
              <br />
              CHOISIR
              <br />
              <span style={{ color: "#ee6931", fontFamily: "Raleway-Italic" }}>
                FRANK
              </span>{" "}
              ?
            </p>
            <span>
              <img src={arrow} alt="arrow" />
            </span>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
            item
            style={{ position: "relative", maxWidth: "300px" }}
          >
            <div
              style={{
                background: "#f9d9b6",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "200px",
              }}
            >
              <img
                src={iconOne}
                style={{ height: "130px", position: "absolute", top: -70 }}
                alt=""
              />
              <div
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "18px",
                  fontWeight: "900",
                  color: "#ee6931",
                }}
              >
                <span style={{ color: "#3026f1" }}>LIVRAISON</span>
                <br />
                ÉCOLOGIQUE
              </div>
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
            item
            style={{ position: "relative", maxWidth: "300px" }}
          >
            <div
              style={{
                background: "#f9d9b6",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "200px",
              }}
            >
              <img
                src={iconTwo}
                style={{ height: "130px", position: "absolute", top: -70 }}
                alt=""
              />
              <div
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "18px",
                  fontWeight: "900",
                  color: "#ee6931",
                }}
              >
                <span style={{ color: "#3026f1" }}>LIVRAISON</span>
                <br />
                ÉCOLOGIQUE
              </div>
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
            item
            style={{ position: "relative", maxWidth: "300px" }}
          >
            <div
              style={{
                background: "#f9d9b6",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "200px",
              }}
            >
              <img
                src={iconThree}
                style={{ height: "130px", position: "absolute", top: -70 }}
                alt=""
              />
              <div
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "18px",
                  fontWeight: "900",
                  color: "#ee6931",
                }}
              >
                <span style={{ color: "#3026f1" }}>LIVRAISON</span>
                <br />
                ÉCOLOGIQUE
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            paddingLeft: "300px",
            paddingRight: "300px",
            marginTop: "100px",
          }}
        >
          <div
            style={{
              borderTop: "5px dotted #fff",
              height: "100px",
              width: "100%",
            }}
          ></div>
        </Grid>

        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            xs={8}
            item
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              // paddingLeft: "50px",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "100%", paddingLeft: "40px" }}>
              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  fontSize: "45px",
                  fontWeight: "900",
                  color: "#ee6931",
                  // textAlign: "justify",
                }}
              >
                LA MARKETPLACE FRANK
                <span
                  style={{ fontFamily: "Raleway-Italic", color: "#3026f1" }}
                >
                  ,<br />
                  POUR LES PARTICULIERS
                  <br />
                  COMME POUR LES PROFESSIONNELS
                </span>
              </p>
            </div>

            <div style={{ display: "flex", columnGap: "60px" }}>
              <div className="image-container-one">
                <div className="image-container">
                  <img
                    src={imageParticular}
                    alt="Particulier"
                    className="background-image"
                  />
                  <div className="gradient-overlay">
                    <h2
                      style={{
                        textAlign: "left",
                        padding: "10px",
                        fontFamily: "Raleway-Italic",
                        fontWeight: "900",
                      }}
                    >
                      PARTICULIERS
                    </h2>
                  </div>
                </div>
                <div style={{ fontWeight: "600" }} className="text-container">
                  <p>
                    Avec Frank, vous pourrez bientôt mettre en vente vos fonds
                    de tiroirs et opter pour une livraison verte, à prix
                    réduits.
                  </p>
                  <p>
                    Notre envie ? Vous permettre de vider vos placards tout en
                    remplissant vos poches !
                  </p>
                </div>
              </div>

              <div className="image-container-one">
                <div className="image-container">
                  <img
                    src={imageProfessional}
                    alt="Particulier"
                    className="background-image"
                  />
                  <div className="gradient-overlay">
                    <h2
                      style={{
                        textAlign: "left",
                        padding: "10px",
                        fontWeight: "900",
                        fontFamily: "Raleway-Italic",
                      }}
                    >
                      PROFESSIONNELS
                    </h2>
                  </div>
                </div>
                <div style={{ fontWeight: "600" }} className="text-container">
                  <p>
                    Et si vous êtes un professionnel, notre service vous
                    permettra de mettre en ligne vos articles en un rien de
                    temps !
                  </p>
                  <p>
                    Le plus ? Avec Frank des ventes assurées et une livraison
                    plus responsable.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                borderTop: "5px dotted #fff",
                // height: "100px",
                width: "100%",
                zIndex: "11111111",
              }}
            ></div>
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-30%",
            zIndex: "-1",
          }}
        >
          <img
            src={imageBannerTwo}
            style={{ width: "100%", objectFit: "cover" }}
            alt="image"
          />
        </div>

        {/* <Grid
          container
          spacing={spacing?.spacing2}
          className="homepage-banner-two"
        >
          <Grid
            xs={12}
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ borderTop: "4px dotted #fff", width: "70%" }}> </div>
          </Grid>

          <Grid
            xs={12}
            item
            style={{ position: "absolute", top: "-620px", zIndex: -1 }}
          >
            <img
              src={imageBannerTwo}
              style={{ width: "100%", objectFit: "cover" }}
              alt="image"
            />
          </Grid>
        </Grid> */}

        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            paddingLeft: "200px",
            paddingRight: "200px",
          }}
        >
          <Grid xs={12} item>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontSize: "45px",
                fontWeight: "900",
                textAlign: "center",
                color: "#3026f1",
              }}
            >
              FRANK, C'EST AUSSI L'ALTERNATIVE
              <br />
              DE LIVRAISON POUR LES SITES
              <br /> E-COMMERCE
            </p>
          </Grid>

          <Grid
            xs={12}
            md={6}
            item
            style={{ fontFamily: "Raleway-Variable", paddingRight: "20px" }}
          >
            <p style={{ fontWeight: "600" }}>
              Frank offre un service de livraison éco-responsable, économique et
              flexible pour la vente de vos produits comme pour vos livraisons.
            </p>
            <br />
            <p>
              Frank s’occupe de la collecte, la préparation de commande et même
              la livraison au client final.
            </p>
          </Grid>
          <Grid
            xs={12}
            md={6}
            item
            style={{ fontFamily: "Raleway-Variable", paddingLeft: "20px" }}
          >
            <p>
              Vous pourrez aussi intégrer la solution Frank dans vos systèmes de
              gestion existants via des{" "}
              <span style={{ fontWeight: "600" }}> API</span>. Pour en savoir
              plus contactez nous sur
              <br />{" "}
              <span style={{ fontWeight: "600" }}>
                <a
                  href="mailto:Hello@frankme.com"
                  style={{
                    textDecoration: "none",
                    color: "#000",
                  }}
                >
                  Hello@frankme.com
                </a>
              </span>{" "}
            </p>
          </Grid>
        </Grid>

        <div
        className="frame-175 display-flex"
        style={{ position: "relative", marginTop: "150px" }}
      >
        <div
          style={{
            borderRadius: "20px",
            zIndex: 1111,
            width: "1000px",
            position: "absolute",
            top: -50,
          }}
        >
          <img src={footer} style={{width:'1000px',objectFit:'contain'}} alt="footer-img" />
          <div style={{ position: "absolute", bottom: 80, right: "12%" }}>
            <h3
              style={{
                fontSize: "36px",
                fontWeight: "900",
                fontFamily: "Raleway-Italic",
                color: defaultLabelColors.white,
                marginBottom:'30px'
              }}
            >
              Téléchargez l'application <br />
              mobile Frank
            </h3>
            <p
              style={{
                color: defaultLabelColors.white,
                fontSize: 24,
                fontFamily: "Raleway-Italic",
                marginBottom:'30px'
              }}
            >
              Rejoignez gratuitement la communauté
              <br /> de livraison verte
            </p>
            <div style={{ display: "flex" }}>
              <a
              rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.frankc2c"
                target="_blank"
                style={{ width: 160 }}
              >
                <img
                  src={googlePlayBtn}
                  style={{ height: "50px", width: "160px" }}
                  alt="google-play"
                /> 
              </a>
              <a
              rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/frank/id6502341979"
                target="_blank"
                style={{ width: 160, marginLeft: "10px" }}
              >
                <AppStoreFR />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-new" style={{paddingLeft:'120px', display:"flex",justifyContent:'center',alignItems:'center'}}>
          <div className="contain">
            <div className="div-line-123" style={{width:'1100px'}}></div>

            <div className="col">
              <h1>
                {" "}
                <FrankIcon fill={"#fff"} height={60} />
              </h1>
            </div>
            <div className="col">
              <h1>Particulier</h1>
              <ul>
                <li >Envoyer un colis</li>
                <li >Transporteur</li>
                <li  >Devenir transporteur</li>
                <li>Démenagement</li>

                <li style={{ marginTop: "10px", fontWeight: "700" }}>
                  Mon compte
                </li>
                <li style={{ fontWeight: "700" }}>Messagerie</li>
              </ul>
            </div>
            <div className="col">
              <h1>Transporteur</h1>
              <ul>
                <li >Devenir transporteur</li>
                <li  >Voir les annonces </li>
                <li> une livraison</li>
              </ul>
            </div>
            {/* <div className="col">
                  <h1>Déménagement </h1>
                  <ul>
                    <li>Déménageur particulier</li>
                    <li>Déménageur professionnel</li>
                  </ul>
                </div> */}
            <div className="col">
              <h1 >Marketplace</h1>
              <ul></ul>
            </div>
            <div className="col">
              {/* <h1>Stockage colis</h1>
                  <ul>
                    <li>Trouver un point relais particulier </li>
                    <li> Proposer un espace de point relais</li>
                    <li>Consigner bagage</li> */}
              <ul>
                <li
                  style={{
                    marginTop: "10px",
                    fontWeight: "700",
                    fontSize: "18px",
                  }}
                >
                  Blog
                </li>
                <li style={{ fontWeight: "700", fontSize: "18px" }}>FAQ</li>
              </ul>
            </div>
            <div className="col" style={{ width: "190px", height: 200 }}>
              <div
                style={{
                  border: "1px solid #fff",
                  borderRadius: "12px",
                  marginTop: "20px",
                  padding: "20px",
                  width: "180px",
                }}
              >
                <h1 style={{ padding: 0 }}>Nous Contacter:</h1>
                <ul>
                  <li>hello@frankme.com</li>
                  <li> +33 6 07 94 80 55</li>
                  <li>91 avenue de la République 75011 Paris</li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: "70px",
                  gap: "10px",
                  marginTop: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <div
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/profile.php?id=61561661727623",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  style={{
                    cursor: "pointer",
                    display: "inline-flex", // Ensure the image fills the div
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/profile.php?id=61561661727623",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                    src={facebookIcon}
                    alt="facebook-icon"
                    style={{
                      width: "100%", // Make image fill the div
                      height: "100%", // Adjust height if needed
                      display: "block", // Remove gaps around the image
                      zIndex: "11111",
                    }}
                  />
                </div>

                <div
               onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/fr-frank/",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
                  style={{
                    cursor: "pointer",
                    display: "inline-flex", // Ensure the image fills the div
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                 onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/fr-frank/",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                    src={instaIcon}
                    alt="facebook-icon"
                    style={{
                      width: "100%", // Make image fill the div
                      height: "100%", // Adjust height if needed
                      display: "block", // Remove gaps around the image
                      zIndex: "11111",
                    }}
                  />
                </div>

                <div
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/frankme.fr/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  style={{
                    cursor: "pointer",
                    display: "inline-flex", 
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/frankme.fr/",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    } src={linkedInIcon} alt="linkedin-icon"
                    style={{
                      width: "100%", // Make image fill the div
                      height: "100%", // Adjust height if needed
                      display: "block", // Remove gaps around the image
                      zIndex: "11111",
                    }} />
                </div>
              </div>
            </div>
            <div
              className="col"
              style={{
                width: "750px",
                marginLeft: "170px",
                fontFamily: "Raleway-Italic",
                fontSize: "14px",
                color: "#fff",
              }}
            >
              <a
              rel="noopener noreferrer"
                href="https://frankme.com/privacy-policy"
                target="_blank"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                Politique de confidentialité
              </a> 
              &nbsp;|{" "}
              <a
              rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#fff" }}
                href="https://frankme.com/terms-and-condition"
                target="_blank"
              >
                Termes et conditions
              </a>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      </div>
      <div className="mobile-screen">
        <MobileView />
      </div>
    </>
  );
}

export default LandingPage;
